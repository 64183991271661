import * as React from 'react';

type Props = {
    validName: boolean;
    name: string;
    description: any;
    isStoreScanReport:boolean;
    setName(value: string): void;
    setDescription(value: string): void;
    setValidName(b: boolean): void;
    setIsStoreScanReport(b: boolean): void;

};
export const ReportFormFields = (props: Props) => {
    const handleOnNameChange = (value: string) => {
        props.setName(value);
        if (value && value.trim().length > 0) {
            props.setValidName(true);
        } else {
            props.setValidName(false);
        }
    };
    return (
        <div className="report-form-fields">
            <div className="form-group">
                <label htmlFor="reportName">Report Name</label>
                <input
                    id="reportName"
                    type="text"
                    value={props.name}
                    className="form-control"
                    onChange={(e) => handleOnNameChange(e.target.value)}
                />
                {!props.validName && (
                    <div className="error-message">
                        Please enter report Name
                    </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="reportDescription">Report Description</label>
                <input
                    id="reportDescription"
                    type="text"
                    value={props.description}
                    className="form-control"
                    onChange={(e) => props.setDescription(e.target.value)}
                />
            </div>

            <div className="form-group">
                <label style={{display:'block'}} htmlFor="isStoreScanReport">Store Scan Report</label>
                <input
                    id="isStoreScanReport"
                    className='form-check-input check-custom-style'
                    type="checkbox"
                    checked={props.isStoreScanReport}
                    onChange={(e) => props.setIsStoreScanReport(e.target.checked)}
                />
            </div>
        </div>
    );
};
