import React, { useEffect, useState } from 'react';
import './scan-reports.scss';
import {
    ColumnChooser,
    ColumnDirective,
    ColumnsDirective,
    DataStateChangeEventArgs,
    Grid,
    GridComponent,
    InfiniteScroll,
    Inject,
    Page,
    Resize,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import ServiceHandler from '../../../services/service-handler';
import {
    QueryBuilderComponent,
    RuleChangeEventArgs,
} from '@syncfusion/ej2-react-querybuilder';
import { ScanReportsResponse } from '../../../services/reports-service/reports-service';
import {
    downloadExcel,
    getCriteriaRequest,
    joinVisibleColumns,
    runPreviewRequest,
    scanReportPageOptions,
} from './scan-reports-util';
import { ScanReportsGridConfig } from './scan-reports-config';
import {
    ChangeEventArgs,
    DropDownList,
    MultiSelect,
    MultiSelectChangeEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { getComponent } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ExcelICONSVG from '../../../assets/ExcelSvgIcon.svg';
import SaveBtnSvg from '../../../assets/SaveButton.svg';
import deleteIcon from '../../../icons/deleteNew.svg';
import { RuleModel } from '@syncfusion/ej2-querybuilder/src/query-builder/query-builder-model';
import { ItemModel } from '@syncfusion/ej2-react-navigations';

import { ReportFormFields } from './report-form-fields';
import notify from '../../../services/toasterService';
import { dropdownOperator } from './query-builder-util';
import { getDynamicValues } from '../utils';
import axios from 'axios';

type Props = {
    selectedRowId?: number;
    setViewReportDetails(value: boolean): void;
    setSelectedRowId(value: number | undefined): void;
};
export const ScanReportDetails = (props: Props) => {
    const [reportName, setReportName] = useState('');
    const [validReportName, setValidReportName] = useState<boolean>(true);
    const [isStoreScanReport,setIsStoreScanReport] = useState<boolean>(false);
    const [reportDescription, setReportDescription] = useState('');
    const [data, setData] = useState<ScanReportsResponse>();
    const [criteria, setCriteria] = useState<string>('');
    const [selectedColumns, setSelectedColumns] = useState<string>('');
    const [criteriaRule, setCriteriaRule] = useState<RuleModel>();
    const [validCriteria, setValidCriteria] = useState<boolean>(false);
    const [reportStatus, setReportStatus] = useState<boolean>(false);
    const [periods, setPeriods] = useState<any[]>([]);
    let gridRef: Grid;
    let queryBuilderRef: QueryBuilderComponent;
    const updateRule = (args: RuleChangeEventArgs) => {
        const validRule = queryBuilderRef.getValidRules(args.rule);
        setCriteriaRule(queryBuilderRef.getValidRules(args.rule));
        if (validRule.rules && validRule.rules.length > 0) {
            setValidCriteria(true);
        } else {
            setValidCriteria(false);
        }
        const filterJson = JSON.stringify(validRule, null, 4);
        setCriteria(filterJson);
    };

    const runPreview = (state: DataStateChangeEventArgs) => {
        if (!validCriteria) {
            notify('Need filter Criteria to preview report.', 'error');
            return;
        }
        if (criteriaRule) {
            ServiceHandler.ReportsService.getScannedReports(
                runPreviewRequest(criteriaRule, state, isStoreScanReport)
            )
                .then((data) => {
                    setData(data);
                    gridRef.dataSource = data;
                })
                .catch(() => {
                    gridRef.dataSource = [];
                    notify('Failed to run preview', 'error');
                });
        }
    };
    const onGridCreated = () => {
        updateRule({
            rule: queryBuilderRef.getValidRules(queryBuilderRef.rule),
        });
    };
    const onActionComplete = (args: any) => {
        if (args.requestType === 'columnstate') {
            setSelectedColumns(joinVisibleColumns(gridRef.getVisibleColumns()));
        }
    };
    const createCriteria = (runReport: boolean) => {
        if (reportName && reportName.length > 0) {
            setValidReportName(true);
        } else {
            setValidReportName(false);
            return;
        }
        //For run report make sure , valid criteria
        if (runReport && !validCriteria) {
            notify('Cannot run report without criteria', 'error');
            return;
        }
        const request = getCriteriaRequest(
            runReport,
            reportName,
            reportDescription,
            selectedColumns,
            criteria,
            isStoreScanReport
        );
        if (props.selectedRowId) {
            ServiceHandler.ReportsService.updateCriteria(
                request,
                props.selectedRowId
            )
                .then(() => {
                    notify('Updated Report Criteria Successfully', 'success');
                })
                .catch(() => {
                    notify('Failed to Update report Criteria', 'error');
                });
        } else {
            ServiceHandler.ReportsService.saveCriteria(request)
                .then((data) => {
                    props.setSelectedRowId(data.id);
                    notify('Saved Report Criteria Successfully', 'success');
                })
                .catch(() => {
                    notify('Failed to save report Criteria', 'error');
                });
        }
    };
    const deleteCriteria = () => {
        if (props.selectedRowId) {
            ServiceHandler.ReportsService.delete(props.selectedRowId)
                .then(() => {
                    notify('Deleted Report Criteria Successfully', 'success');
                    backToAll();
                })
                .catch(() => {
                    notify('Failed to Delete Report Criteria', 'error');
                });
        }
    };
    const runPreviewButton: ItemModel = {
        text: 'RUN PREVIEW',
        click: () => {
            runPreview({ skip: 0 });
        },
        cssClass: 'preview-report',
        align: 'Right',
    };
    const toolbarOptions: any = ['ColumnChooser', runPreviewButton];
    const backToAll = () => {
        props.setSelectedRowId(undefined);
        props.setViewReportDetails(false);
    };

    useEffect(() => {
        if (gridRef) {
            setSelectedColumns(joinVisibleColumns(gridRef.getVisibleColumns()));
        }
        if (props.selectedRowId) {
            ServiceHandler.ReportsService.get(props.selectedRowId).then(
                async (data) => {
                    const endPointURL = getDynamicValues('period');
                    await axios.get(endPointURL).then((res: any) => {
                        console.log(res);
                        if (res.data.length > 0) {
                            setPeriods(res.data);
                        }
                    });
                    setReportName(data.reportName);
                    setIsStoreScanReport(data.isStoreScanReport || false);
                    setReportDescription(data.reportDescription);
                    if (data.reportStatus === 'COMPLETED') {
                        setReportStatus(true);
                    }
                    if (data.criteria) {
                        const rule = JSON.parse(data.criteria);
                        queryBuilderRef.setRules(rule);
                        setCriteriaRule(rule);
                        setCriteria(data.criteria);
                        if (rule && rule.rules?.length > 0) {
                            setValidCriteria(true);
                        }
                    }
                    if (data.selectedColumns) {
                        setSelectedColumns(data.selectedColumns);
                    }
                }
            );
        } else {
            getPeriods();
        }
    }, []);

    const getPeriods = async () => {
        const endPointURL = getDynamicValues('period');
        await axios
            .get(endPointURL)
            .then((res: any) => {
                console.log(res);
                if (res.data.length > 0) {
                    setPeriods(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function customDropdown() {
        let elem: HTMLElement;
        let dropDownObj: DropDownList;
        let multiSelectObj: MultiSelect;
        let inOperators: string[] = ['in', 'notin'];
        let template = {
            create: () => {
                elem = document.createElement('input');
                elem.setAttribute('type', 'text');
                return elem;
            },
            destroy: (args: { elementId: string }) => {
                multiSelectObj = getComponent(
                    document.getElementById(args.elementId) as HTMLElement,
                    'multiselect'
                ) as MultiSelect;
                if (multiSelectObj) {
                    multiSelectObj.destroy();
                }
                dropDownObj = getComponent(
                    document.getElementById(args.elementId) as HTMLElement,
                    'dropdownlist'
                ) as DropDownList;
                if (dropDownObj) {
                    dropDownObj.destroy();
                }
            },
            write: (args: {
                elements: Element;
                values: string[] | string;
                operator: string;
            }) => {
                if (inOperators.indexOf(args.operator) > -1) {
                    multiSelectObj = new MultiSelect({
                        change: (e: MultiSelectChangeEventArgs) => {
                            queryBuilderRef?.notifyChange(
                                e.value as string[],
                                e.element
                            );
                        },
                        dataSource: periods,
                        mode: 'Delimiter',
                        fields: { value: 'period', text: 'period' },
                        placeholder: 'Select Period',
                        value: args.values as string[],
                        popupHeight: '200px',
                    });
                    multiSelectObj.appendTo('#' + args.elements.id);
                } else {
                    console.log(args);
                    dropDownObj = new DropDownList({
                        change: (e: ChangeEventArgs) => {
                            queryBuilderRef?.notifyChange(
                                e.value as string,
                                e.element
                            );
                        },
                        dataSource: periods,
                        fields: { value: 'period', text: 'period' },
                        placeholder: 'Select Period',
                        value: args.values as string,
                    });
                    dropDownObj.appendTo('#' + args.elements.id);
                }
            },
        };

        return template;
    }

    return (
        <>
            <div className="scan-report-section control-pane">
                <div className="control-section">
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-4 control-section">
                            <p className="child-tab-title">
                                Scan Report Builder
                            </p>
                        </div>
                        <div className="col-lg-8 control-section ">
                            <ButtonComponent
                                className="reports-button float-right pr-0"
                                onClick={() => createCriteria(false)}
                            >
                                <img src={SaveBtnSvg} alt="Save" />
                            </ButtonComponent>
                            <ButtonComponent
                                className="e-outline opt-btn-light run-report float-right "
                                onClick={() => createCriteria(true)}
                            >
                                RUN REPORT
                            </ButtonComponent>
                            <ButtonComponent
                                className="reports-button float-right "
                                onClick={deleteCriteria}
                            >
                                <img src={deleteIcon} alt="Delete" />
                            </ButtonComponent>
                            <ButtonComponent
                                className="reports-button float-right "
                                onClick={() =>
                                    downloadExcel(props.selectedRowId)
                                }
                            >
                                <img src={ExcelICONSVG} alt="File " />
                            </ButtonComponent>
                        </div>
                    </div>
                    <hr className="reports-hr" />
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-4 control-section">
                            <button
                                className="btn btn-link back-to-all float-left"
                                onClick={backToAll}
                            >
                                {'<'} Back to all scan reports
                            </button>
                        </div>
                        <div className="col-lg-8 control-section">
                            {reportStatus && (
                                <span className="e-outline reports-completed-btn float-right m-2">
                                    Completed
                                </span>
                            )}
                        </div>
                    </div>
                    

                    <div className="row mt-4 mb-4">
                        <div className="col-lg-2 control-section">
                            <ReportFormFields
                                name={reportName}
                                description={reportDescription}
                                isStoreScanReport={isStoreScanReport}
                                setIsStoreScanReport={setIsStoreScanReport}
                                setName={setReportName}
                                setDescription={setReportDescription}
                                validName={validReportName}
                                setValidName={setValidReportName}
                            />
                        </div>
                        <div className="col-lg-10 control-section">
                            <QueryBuilderComponent
                                ref={(g) => {
                                    if (g) queryBuilderRef = g;
                                }}
                                ruleChange={updateRule}
                                columns={[
                                    ...ScanReportsGridConfig,
                                    {
                                        field: 'period',
                                        label: 'Fiscal Period',
                                        type: 'Date',
                                        operators: dropdownOperator,
                                        template: customDropdown(),
                                    },
                                ]}
                            ></QueryBuilderComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 control-section">
                            <GridComponent
                                id="scan-report-grid"
                                ref={(g) => {
                                    if (g) gridRef = g;
                                }}
                                created={onGridCreated}
                                dataSource={data}
                                rowHeight={48}
                                showColumnChooser={true}
                                toolbar={toolbarOptions}
                                allowGrouping={true}
                                actionComplete={onActionComplete}
                                allowResizing={true}
                                pageSettings={scanReportPageOptions}
                                allowPaging={true}
                                height={'434'}
                                dataStateChange={runPreview}
                            >
                                <ColumnsDirective>
                                    {ScanReportsGridConfig.map((col: any) => {
                                        return (
                                            <ColumnDirective
                                                key={col.field}
                                                field={col.field}
                                                headerText={col.label}
                                                textAlign={'Center'}
                                                format={col.format}
                                                type={col.type}
                                            />
                                        );
                                    })}
                                </ColumnsDirective>
                                <Inject
                                    services={[
                                        Toolbar,
                                        ColumnChooser,
                                        InfiniteScroll,
                                        Resize,
                                        Page,
                                    ]}
                                />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
