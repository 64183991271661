import HttpService from '../http-service/http-service';
import {API_PATH, getApiBaseUrl} from '../../api-paths';
import {saveAs} from 'file-saver';
import notify from '../toasterService';

export interface ScanReports {
    id: number;
    description: string;
    scannedBy?: string;
    scannedOn: Date;
    upc: string;
    disposition: string;
    containerId?: any;
    extCost?: any;
    quantity: string;
    storeName: string;
    storeNumber: string;
    vendorNumber: string;
    vendorName: string;
    warehouseLocation: string;
    cost: string;
}

export interface ScanReportsResponse {
    result: ScanReports[];
    count: number;
}

export interface ReportCriteriaRequest {
    reportName: string;
    reportDescription: string;
    selectedColumns: string;
    criteria: string;
}

export interface ReportCriteriaResponse {
    id: number;
    reportName: string;
    reportDescription: string;
    selectedColumns: string;
    criteria: string;
    reportType: string;
    runReportFlag: boolean;
    reportStatus: string;
    csvFilePath: string;
    pdfFilePath: string;
    createdBy: string;
    lastRunOn: Date;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    isStoreScanReport?: boolean;
}

export interface ScanCriteriaListResponse {
    result: ReportCriteriaResponse[];
}

export default class ReportsService {
    public async getScannedReports(query: any): Promise<ScanReportsResponse> {
        const url = getApiBaseUrl() + API_PATH.SCANNED_REPORTS_PATH;
        return HttpService.post<ScanReportsResponse>(url, query).then(
            (response) => {
                return response.data;
            }
        );
    }

    public async saveCriteria(
        query: ReportCriteriaRequest
    ): Promise<ReportCriteriaResponse> {
        const url =
            getApiBaseUrl() + API_PATH.SAVE_SCANNED_REPORT_CRITERIA_PATH;
        return HttpService.post<any>(url, query).then((response) => {
            return response.data;
        });
    }

    public async updateCriteria(
        query: ReportCriteriaRequest,
        id: number
    ): Promise<ReportCriteriaResponse> {
        const url =
            getApiBaseUrl() + API_PATH.GET_SCANNED_REPORT_BASED_ON_ID + id;
        return HttpService.put<any>(url, query).then((response) => {
            return response.data;
        });
    }

    getReportsList(): Promise<ReportCriteriaResponse[]> {
        const url = getApiBaseUrl() + API_PATH.SCANNED_REPORTS_LIST;
        return HttpService.get<any>(url).then((response) => {
            return response.data;
        });
    }

    get(id: number): Promise<ReportCriteriaResponse> {
        const url =
            getApiBaseUrl() + API_PATH.GET_SCANNED_REPORT_BASED_ON_ID + id;
        return HttpService.get<any>(url).then((response) => {
            return response.data;
        });
    }

    delete(id: number): Promise<Boolean> {
        const url =
            getApiBaseUrl() + API_PATH.GET_SCANNED_REPORT_BASED_ON_ID + id;
        return HttpService.delete<any>(url).then((response) => {
            return response.data;
        });
    }

    downloadExcel(id: number) {
        const url =
            getApiBaseUrl() +
            API_PATH.DOWNLOAD_SCANNED_REPORT_BASED_ON_ID +
            id +
            '/download-excel';
        HttpService.get<any>(url, {responseType: 'blob'})
            .then((response) => {
                saveAs(response.data, 'scan-report.xlsx');
            })
            .catch(() => {
                notify('Failed to Download Report as excel file.', 'error');
            });
    }

    downloadPdf(id: number) {
        const url =
            getApiBaseUrl() +
            API_PATH.DOWNLOAD_SCANNED_REPORT_BASED_ON_ID +
            id +
            '/download-pdf';
        HttpService.get<any>(url, {responseType: 'blob'})
            .then((response) => {
                saveAs(response.data, 'scan-reports-pdf.pdf');
            })
            .catch(() => {
                notify('Failed to Download Report as pdf file.', 'error');
            });
    }
}
